




function heightAlternate(){
    aboutHeight = document.querySelectorAll('.about-parallax');
    aboutHeight.forEach(about => {
        
        about.classList.toggle('height-about');
    });

}

function aboutInfo(){

    aboutSwitch = document.querySelectorAll('.about-switch');
    aboutSwitch.forEach(change =>{
        change.classList.toggle('none');
    });
}