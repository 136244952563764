


function showModal(){


    var myModal = new bootstrap.Modal(document.getElementById('modalContact'), {
        keyboard: false
    })

    if(myModal){

    myModal.show();

    }



}



function shadowInterval() {

    setInterval(changeShadow, 3000);

}

function changeShadow() {
    const shadowLogos = document.querySelectorAll('.logo-img');
    shadowLogos.forEach(logo => {
        logo.classList.toggle('logo-shadow');
    });

}


function shadowIntervalTittles() {

    setInterval(changeShadowTittles, 2500);

}


function changeShadowTittles(){

    const shadowTittles = document.querySelectorAll('.tittles');
    shadowTittles.forEach(tittle => {
        tittle.classList.toggle('tittle--shadow');
    });
}


