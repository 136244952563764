document.addEventListener('DOMContentLoaded', function () {
    eventListeners();
    shadowInterval();
    shadowIntervalTittles();

    if (document.querySelector('#modalContact')) {
        showModal();
    }
});






$(function certificates() {


    
    if($('.certificates-content')){

        let content = $('.certificates-content').width();
        
        if(content < 576){

            $('.certificate-colorbox').colorbox({ inline: true, scalePhotos: true, width: "70%", height: "40%" });
        }else{
            if(content > 576 & content < 768){
                $('.certificate-colorbox').colorbox({ inline: true, scalePhotos: true, width: "70%", height: "55%" });
            }
            if(content > 768){
                $('.certificate-colorbox').colorbox({ inline: true, scalePhotos: true, width: "70%", height: "75%" });
            }
        }   


        
    }
    
    $(window).resize(function () {
        if (this.resizeTO) clearTimeout(this.resizeTO);
        this.resizeTO = setTimeout(function () {
            $(this).trigger('resizeEnd');
        }, 500);
    });
    $(window).bind("resizeEnd", function () {
        certificates();
    });
});

function eventListeners(){
   
   
    if (document.getElementById('mobile-bar')) {
        const mobileBar = document.querySelector('.mobile-bar');
        mobileBar.addEventListener('click', responsiveNavegation);
    }
    const body = document.querySelector('body');

    if(document.querySelector('.about-description')){
        const aboutSection = document.querySelector('.about-description');
        aboutSection.addEventListener('click', heightAlternate);
        aboutSection.addEventListener('click', aboutInfo);




    }

    body.addEventListener('click', closeMobileBar);

    

}




function responsiveNavegation() {

   
        const mobileActive = document.getElementById('mobile-menu');
        
        mobileActive.classList.toggle('active');
    
    
}






function closeMobileBar(e){
    
    if(document.querySelector('.mobile-menu-content')){
        const menuContent = document.querySelector('.mobile-menu-content');
        const active = menuContent.classList.contains('active');
        
        const body = e.target.parentElement;
    
    
        if(active !== null){
            
            if(body.classList.contains('mobile')){
                const link = body.classList.contains('link-mobile');
                if(link){
                    
                    
                }else{
                    
                }           
            
            } else {
                if (!body.classList.contains('mobile')) {
                    menuContent.classList.remove('active');
                }
            }
        
        } 

    }
}